import hardiness from '../../data/hardiness.json';

export function getPlantHardinessZone(minTemperature: number): string {
  const hardinessZone = hardiness.find(
    (zone) =>
      (zone.min ?? -Infinity) <= minTemperature &&
      (zone.max ?? Infinity) >= minTemperature
  );

  return `${hardinessZone.zone}`;
}
