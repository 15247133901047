import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';
import * as React from 'react';
import { FC, Fragment } from 'react';

export type HeaderLink = {
  src: string;
  text: string;
};

const headerLinks: HeaderLink[] = [
  {
    src: '/',
    text: 'Home',
  },
  {
    src: '/tree-guilds/',
    text: 'My Tree Guilds',
  },
];

type HeaderProps = {
  overrideHeaders?: HeaderLink[];
};

const Header: FC<HeaderProps> = ({ overrideHeaders }) => {
  const navLinks = overrideHeaders ?? headerLinks;

  return (
    <Popover className="relative font-bold bg-primary text-white ">
      <header className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">Freeden</a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            {navLinks.map((link) => (
              <Link
                key={`${link.src}-${link.text}`}
                to={link.src}
                className="text-base font-medium text-white"
              >
                {link.text}
              </Link>
            ))}
          </Popover.Group>
        </div>
      </header>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          {({ close }) => (
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div className="-mr-2">
                    <Popover.Button className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                  {navLinks.map((link) => (
                    <Link
                      key={`${link.src}-${link.text}`}
                      to={link.src}
                      onClick={() => close()}
                      className="text-base text-center font-medium text-gray-900 hover:text-gray-700"
                    >
                      {link.text}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Header;
