import { getPlantHardinessZone } from '@/lib/connectors/hardiness';
import { useCallback, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

export type TerrainData = {
  hardiness: string;
  minTemperature: number;
  sun: '+' | '/' | '-';
  soilHumidity: 'D' | 'P';
};

export function useTerrainData(): TerrainData & {
  setTerrainData: (newValues: Omit<TerrainData, 'hardiness'>) => unknown;
} {
  const [minTemperature, setMinTemperature] =
    useLocalStorage<TerrainData['minTemperature']>('minTemperature');
  const [sun, setSun] = useLocalStorage<TerrainData['sun']>('sun');
  const [soilHumidity, setSoilHumidity] =
    useLocalStorage<TerrainData['soilHumidity']>('soilHumidity');

  const setTerrainData = useCallback(
    (newValues: TerrainData) => {
      setMinTemperature(newValues.minTemperature);
      setSun(newValues.sun);
      setSoilHumidity(newValues.soilHumidity);
    },
    [setMinTemperature, setSun, setSoilHumidity]
  );

  const hardiness = useMemo(
    () =>
      minTemperature !== undefined
        ? getPlantHardinessZone(minTemperature)
        : undefined,
    [minTemperature]
  );

  return {
    hardiness,
    minTemperature,
    sun,
    soilHumidity,
    setTerrainData,
  };
}
