import trees from '../../data/trees.json';

export type Variety = {
  name: string;
  soulmates: string[];
};

export type Tree = {
  tree: string;
  hardiness: string[];
  sun: string[];
  soilHumidity: string[];
  pollinationType: string[];
  pollinationMedium: string[];
  varieties: Variety[];
  companionPlants: string[];
  warning?: string;
};

export function getViableTrees(filter: {
  hardiness?: string;
  sun?: string;
  soilHumidity?: string;
}): Tree[] {
  return trees.filter(
    (tree) =>
      (!filter.hardiness || ~tree.hardiness?.indexOf(filter.hardiness)) &&
      (!filter.sun || ~tree.sun?.indexOf(filter.sun)) &&
      (!filter.soilHumidity || ~tree.soilHumidity?.indexOf(filter.soilHumidity))
  );
}
