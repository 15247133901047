import Footer from '@/components/Footer';
import Header from '@/components/Header';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type LayoutProps = {
  className?: string;
  hideHeader?: boolean;
};

const Layout: FC<LayoutProps> = ({ children, className, hideHeader }) => {
  return (
    <>
      <div className={clsx(className, 'min-h-screen flex flex-col')}>
        {!hideHeader && <Header />}
        <main className="flex-1">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
