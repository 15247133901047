import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import Helmet from 'react-helmet';

import { DefaultSeoQuery } from '../../graphql-types';

export type ImageSEOProps = {
  src: string;
  width: number;
  height: number;
};

type SEOProps = {
  title: string;
  description: string;
  url: string;
  image?: ImageSEOProps;
  disableIndex?: boolean;
};

const SEO: FC<SEOProps> = ({
  title,
  description,
  url,
  image,
  disableIndex,
}) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery<DefaultSeoQuery>(graphql`
    query DefaultSEO {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `);

  const pageTitle = title || siteMetadata.title;
  const fullUrl = `${siteMetadata.siteUrl}/${
    url ? (url.indexOf('/') === 0 ? url.substring(1) : url) : ''
  }`;
  const imageUrl = `${siteMetadata.siteUrl}${image ? image.src : '/logo.png'}`;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={pageTitle}
      titleTemplate={`%s - ${siteMetadata.title}`}
    >
      {disableIndex && <meta name="robots" content="noindex" />}

      {/* General tags */}
      <meta charSet="UTF-8" />
      <meta name="description" content={description} />
      <meta name="image" content={imageUrl} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={fullUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta
        property="og:image:width"
        content={image ? `${image.width}` : '1200'}
      />
      <meta
        property="og:image:height"
        content={image ? `${image.height}` : '628'}
      />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
};

export default SEO;
