import * as React from 'react';
import { FC } from 'react';

type FooterProps = {};

const Footer: FC<FooterProps> = () => {
  return (
    <footer className="relative z-10 bg-primary p-10 mt-20">
      <div className="flex flex-col-reverse lg:flex-row uppercase font-light text-xs text-white">
        <div className="flex-1 flex flex-col justify-end">
          © 2022 EcoSistemic Utopia, All rights reserved.
        </div>
        <div className="flex-1 flex flex-col justify-end lg:flex-row space-y-1 mb-12 lg:space-y-0 lg:mb-0 lg:space-x-5">
          <div className="flex flex-col gap-4 items-center">
            <div>Sponsored by</div>
            <img
              className="h-20"
              src="/EIT-CKIC-Logo_Transparent_Standard.png"
              alt="EIT logo"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
